import React from 'react'
import { Link } from 'gatsby'

import { useCustomer, useLogin, useAllocation } from 'gatsby-theme-harlan'
import compass_icon from '../images/compass-icon.png'

const Footer = (props) => {
  const { customer, isLoggedIn, isCustomerLoading } = useCustomer()
  const { logout } = useLogin()
  const { isAllocationLoading, allocationStatus } = useAllocation()

  return (
    <footer>
      {props && props.location && props.location.pathname && props.location.pathname !== '/' && props.location.pathname !== '' && (
        <div className="footer-wrapper max-w-3xl mx-auto">
          <div className="footer-icon pb-12 text-center">
            <img height="30px" width="30px" src={compass_icon} className="mx-auto" alt="compass icon" />
          </div>
          <div id="footer" className={`description w-full`}>
            <div className={'nav-menu'}>
              {!isCustomerLoading && (
                <>
                  {isLoggedIn && (
                    <>
                      <Link className={'cursor-pointer'} to={'/profile'}>
                        Account
                      </Link>
                      <a className={'cursor-pointer'} onClick={logout}>
                        Logout
                      </a>
                    </>
                  )}
                </>
              )}
              <Link className="link" to="/bottle-verification">
                Bottle Verification
              </Link>
              {/* <Link
                  key={idx}
                  className={'link'}
                  to={page.link}
                  getProps={isActivePartially('link')}
              ></Link> */}
              {/* {
                  pageList.map((page, idx) => {
                      return (
                          page.onClick || page.temp ?
                              <a
                                  key={idx}
                                  className={'link'}
                                  style={!page.temp ? { cursor: "pointer" } : {}}
                                  onClick={page.onClick || null}
                              >{page.name}</a> :
                              <Link
                                  key={idx}
                                  className={'link'}
                                  to={page.link}
                                  getProps={isActivePartially('link')}
                              >{page.name}</Link>
                      )
                  })
              } */}
            </div>

            <div className="contact">
              <div className="instructions">
                <a href="tel:(707) 944-9445">(707) 944-9445</a>
              </div>
              <div className="instructions">
                <a href="mailto:info@bond.wine">INFO@BOND.WINE</a>
              </div>
              <div className="instructions">
                <p>P.O. Box 426 Oakville, CA 94562</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </footer>
  )
}

export default Footer
