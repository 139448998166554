import { useMemo } from 'react'
import * as Sentry from '@sentry/gatsby'

if (typeof window !== 'undefined' && !window.__alertShown) {
  window.__alertShown = false
}

const useStorage = () => {
  const isBrowser = typeof window !== 'undefined'

  if (!isBrowser) {
    return {
      setItem: () => {
        console.error('No storage available for setItem')
      },
      getItem: () => {
        console.error('No storage available for getItem')
        return null
      },
      removeItem: () => {
        console.error('No storage available for removeItem')
      },
      clear: () => {
        console.error('No storage available for clear')
      },
      storageType: 'none',
    }
  }

  const isStorageAvailable = (type) => {
    try {
      const storage = window[type]
      const testKey = '__storage_test__'
      storage.setItem(testKey, testKey)
      storage.removeItem(testKey)
      return true
    } catch (e) {
      return false
    }
  }

  const getStorageConfig = useMemo(() => {
    if (isStorageAvailable('localStorage')) {
      return { storage: localStorage, storageType: 'localStorage' }
    } else if (isStorageAvailable('sessionStorage')) {
      console.log('localStorage is not available, using sessionStorage')
      return { storage: sessionStorage, storageType: 'sessionStorage' }
    } else {
      if (!window.__alertShown) {
        window.__alertShown = true
        console.error('Both localStorage and sessionStorage are not available')
        alert('It appears cookies are disabled in your browser. Please enable cookies to access your allocation.')
        Sentry.captureMessage('Both localStorage and sessionStorage are not available')
      }
      return { storage: null, storageType: 'none' }
    }
  }, [])

  const setItem = (key, value) => {
    const { storage, storageType } = getStorageConfig
    if (storage) {
      try {
        storage.setItem(key, value)
        console.log(`Set ${key} to ${value} in ${storageType}`)
      } catch (e) {
        console.error(`Error setting ${key} in ${storageType}`, e)
      }
    } else {
      console.error('No storage available for setItem')
    }
  }

  const getItem = (key) => {
    const { storage, storageType } = getStorageConfig
    if (storage) {
      try {
        const value = storage.getItem(key)
        // console.log(`Got ${key} from ${storageType}: ${value}`)
        return value
      } catch (e) {
        console.error(`Error getting ${key} from ${storageType}`, e)
        return null
      }
    }
    console.error('No storage available for getItem')
    return null
  }

  const removeItem = (key) => {
    const { storage, storageType } = getStorageConfig
    if (storage) {
      try {
        storage.removeItem(key)
        console.log(`Removed ${key} from ${storageType}`)
      } catch (e) {
        console.error(`Error removing ${key} from ${storageType}`, e)
      }
    } else {
      console.error('No storage available for removeItem')
    }
  }

  const clear = () => {
    const { storage, storageType } = getStorageConfig
    if (storage) {
      try {
        storage.clear()
        console.log(`Cleared ${storageType}`)
      } catch (e) {
        console.error(`Error clearing ${storageType}`, e)
      }
    } else {
      console.error('No storage available for clear')
    }
  }

  return { setItem, getItem, removeItem, clear, storageType: getStorageConfig.storageType }
}

export default useStorage
