import { graphql, useStaticQuery } from 'gatsby'

const useC7Settings = () => {
  const { settings } = useStaticQuery(graphql`
    query C7Settings {
      settings: c7Settings {
        ...SettingsFields
      }
    }
  `)

  return settings
}

export default useC7Settings
