import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import useStorage from '../auth/useStorage' // Adjust the import path as necessary

export default function useToken() {
  const queryClient = useQueryClient()
  const { getItem, removeItem } = useStorage()

  const tokenQuery = useQuery({
    queryKey: ['token'],
    queryFn: () => {
      const token = getItem('token') || undefined
      // console.log(`Token retrieved: ${token}`)
      const decodedTokenPayload = token && atob(token?.split?.('.')?.[1])
      const data = decodedTokenPayload && JSON.parse(decodedTokenPayload)

      const tokenExpiryDate = getItem('tokenExpiryDate') || undefined
      // console.log(`Token Expiry Date retrieved: ${tokenExpiryDate}`)

      // Set token in header here
      if (token) {
        axios.defaults.headers.common['Authorization'] = `${token}`
      } else {
        delete axios.defaults.headers.common['Authorization']
      }
      return { token, tokenExpiryDate, customerId: data?.sub }
    },

    onSettled: (data) => {
      if (!data?.token) {
        queryClient.invalidateQueries(['customer']) // clear customer data if no auth token exists
      }

      handleExpiredToken({ data, queryClient, removeItem })
    },
    refetchOnWindowFocus: false, // Prevent refetching when the window is refocused
  })

  return {
    token: tokenQuery.data?.token,
    tokenExpiryDate: tokenQuery.data?.tokenExpiryDate,
    customerId: tokenQuery.data?.customerId,
    ...tokenQuery,
  }
}

const handleExpiredToken = ({ data, queryClient, removeItem }) => {
  if (data?.tokenExpiryDate) {
    const now = new Date()
    const tokenExpiryDate = new Date(data.tokenExpiryDate)
    const isExpired = now.getTime() > tokenExpiryDate.getTime()

    if (isExpired) {
      removeItem('token')
      removeItem('tokenExpiryDate')
      removeItem('cartId')

      queryClient.removeQueries({ queryKey: ['token'], exact: true })
      queryClient.removeQueries({ queryKey: ['cartId'], exact: true })
      queryClient.removeQueries({ queryKey: ['customer'], exact: true })
    }
  }
}
