import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import useAxiosConfig from '../hooks/useAxiosConfig'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // globally default to 30 seconds
      staleTime: 1000 * 30,
    },
  },
})

const AxiosProvider = ({ children, tenantName }) => {
  useAxiosConfig(tenantName)
  return <>{children}</>
}

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AxiosProvider tenantName={process.env.GATSBY_C7_TENANT}>{children}</AxiosProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
