import * as Sentry from '@sentry/gatsby'

export const setSentryCustomer = ({ id, email }) => {
  if (Sentry) {
    Sentry.setUser({ id: id, email: email })
  }
  return true
}

export const clearSentryCustomer = () => {
  if (Sentry) {
    Sentry.setUser(null)
  }
  return true
}
