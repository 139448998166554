import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import enUsCommon from './locale/en-US/common.json'
// import enUsNotification from './locale/en-US/notification.json'
import enUsValidation from './locale/en-US/validation.json'

i18next.use(initReactI18next).init({
  resources: {
    en: {
      common: enUsCommon,
      // notification: enUsNotification,
      validation: enUsValidation,
    },
  },
  ns: ['common', 'notification', 'validation'],
  defaultNS: 'common',
  lng: 'en-US',
  fallbackLng: 'en-US',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
})

export default i18next
