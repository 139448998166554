import { useQuery } from '@tanstack/react-query'

import useToken from '../auth/useToken'
import axios from 'axios'

type AllocationStatus = 'account-info' | 'offering' | 'thank-you' | 'allocation-expired' | undefined

export default function useAllocation({
  onSuccess,
}: {
  onSuccess?: (data: { allocation: any; allocationStatus: AllocationStatus; isAllocationLoading: Boolean }) => void
} = {}) {
  const { token, customerId } = useToken()

  const allocationQuery = useQuery({
    queryKey: ['allocation', customerId],
    queryFn: async () => {
      console.log('allocationQuery about to run, using token:', token)
      try {
        const allocationResponse = await axios.get(
          `https://api.commerce7.com/v1/pacific-union/customer/${customerId}/allocation`
        )
        const allocations = allocationResponse.data

        console.log('allocationResponse.data allocations', allocations)

        // if allocation is an array, pick the first one
        // if (allocations && Array.isArray(allocations) && allocations.length > 0) {
        //   console.log('getAllocationStatus is array, sorting...')
        //   const allocation = allocations.sort(function (a, b) {
        //     return b.startDate - a.startDate
        //   })[0]
        // }

        if (allocations?.[0]?.id) {
          try {
            const allocationDetailsResponse = await axios.get(
              `https://api.commerce7.com/v1/pacific-union/customer/${customerId}/allocation/${allocations?.[0]?.id}`
            )
            const allocationDetails = allocationDetailsResponse.data
            return {
              allocation: allocationDetails,
              allocationStatus: getAllocationStatus(allocationDetails),
            }
          } catch (detailError) {
            console.error('Error fetching allocation details:', detailError)
            // Handle or throw allocation details fetch error appropriately
            throw detailError
          }
        } else {
          return {
            allocation: undefined,
            allocationStatus: 'account-info',
            isAllocationLoading: false,
          }
        }
      } catch (allocationError) {
        console.error('Error fetching allocation:', allocationError)
        // Handle or throw allocation fetch error appropriately
        throw allocationError
      }
    },
    enabled: !!customerId && !!token,
    onSuccess: (data) => {
      // console.log('useAllocation onSuccess running')
      if (onSuccess) {
        onSuccess({
          allocation: data?.allocation,
          allocationStatus: data?.allocationStatus || 'account-info',
          isAllocationLoading: false,
        })
      }
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
  return {
    ...allocationQuery,
    isAllocationLoading: allocationQuery.isLoading,
    allocation: allocationQuery?.data?.allocation,
    allocationStatus: allocationQuery?.data?.allocationStatus,
  }
}

export const getAllocationStatus = (allocation): AllocationStatus => {
  if (!allocation) {
    return 'account-info'
  }

  const currentTime = new Date(Date.now())
  const { pendingOrders, startDate, endDate } = allocation
  const [start, end] = [new Date(startDate), new Date(endDate)]

  // console.log('getAllocationStatus allocation:', allocation)

  // allocation is active
  if (currentTime > start && currentTime < end) {
    // console.log('pendingOrders', pendingOrders)
    // order already placed
    if (pendingOrders && pendingOrders.length > 0) {
      // console.log('allocationStatus == thank-you');
      return 'thank-you'
    }
    // console.log('allocationStatus == offering');
    // order not placed
    return 'offering'
  }

  // allocation not active

  // return allocation-expired if allocation has already started and then ended within the past month
  const oneMonthAgo = new Date()
  oneMonthAgo.setDate(oneMonthAgo.getDate() - 30)

  if (currentTime > start && oneMonthAgo < end) {
    // console.log('allocationStatus == allocation-expired');
    if (pendingOrders?.length > 0) {
      return 'thank-you'
    }
    return 'allocation-expired'
  }

  // console.log('allocationStatus == account-info default');
  return 'account-info'
}
