const { wrapRootElement } = require('./gatsby-shared')
const Sentry = require('@sentry/gatsby')
exports.wrapRootElement = wrapRootElement

if (process.env.GATSBY_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    environment:
      process.env.NODE_ENV == 'development' || process.env.GATSBY_SENTRY_ENV == 'development'
        ? 'development'
        : 'production',
    debug: false,
    integrations: [Sentry.browserTracingIntegration(), Sentry.httpClientIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 0.1,
    release: '1.0.0',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
