import i18next from '../translations'

export function removeNonAlphanumericCharacters(input) {
  return input && input.replace(/[^0-9a-z]/gi, '')
}

export function prepareAppTitle(appName, pathname) {
  if (pathname == '') {
    return appName
  }

  if (pathname == '/') {
    return `${appName} - ${i18next.t('home-page')}`
  }

  return `${appName} - ${getPageTitle(pathname)}`
}

// get clean page slug to use as id for <main> element
export function getPageSlug(pathname) {
  if (pathname == '' || pathname == '/' || !pathname) {
    return 'home'
  } else {
    // @todo: use replaceAll at some point https://caniuse.com/?search=replaceAll (@ 90% now)
    // return pathname.replaceAll('/', '')
    var path_components = pathname.split('/')
    if (path_components[0] == '') path_components.shift()

    if (
      path_components &&
      path_components[0] &&
      path_components[0] == 'profile' &&
      path_components.length > 1 &&
      path_components[1]
    ) {
      return path_components[1]
    } else if (path_components && path_components[0]) {
      return path_components[0]
    }
    return ''
  }
}

export function getPageTitle(pathname) {
  var pageTitle = ''
  // remove leading and trailing slash
  if (pathname.charAt(0) == '/') {
    pathname = pathname.substring(1)
  }
  if (pathname[pathname.length - 1] == '/') {
    pathname = pathname.slice(0, -1)
  }
  // split on slash and go with second element
  var strings = pathname.split('/')
  pageTitle = strings.length == 1 ? strings[0] : strings[1]
  // split on dashes and capitalize first letter of each word
  pageTitle = pageTitle
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

  // manual overrides
  if (pageTitle == 'Faqs') {
    pageTitle = 'FAQs'
  } else if (pageTitle == 'St Eden') {
    pageTitle = 'St. Eden'
  }

  // lowercase prepositions
  pageTitle = pageTitle.replace(' And ', ' and ')

  return pageTitle
}

// get page title to stick in html title after $estate -
// export function getPageTitle(pathname) {

//     if (pathname == '/') {
//       return '';
//     }

//     let pathsElements = pathname.split("/")
//     let words = pathname.split("-");

//     if(pathsElements.length > 0) {
//       words = pathsElements[pathsElements.length-1].split("-")
//     }

//     let result = '';
//     words.forEach(word => {
//       let clearWord = removeNonAlphanumericCharacters(word)
//       result += clearWord.charAt(0).toUpperCase() + clearWord.slice(1) + ' ';
//     });

//     return result;
// }
