import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import useAllocation from '../allocation/useAllocation'
import useLogin from '../auth/useLogin'
import useToken from '../auth/useToken'

type CustomerOptions = {
  onNoAccess?: () => void
  onHasAccess?: () => void
}

export default function useCustomer({
  onNoAccess,
  onHasAccess,
}: CustomerOptions = {}) {
  const { isLoading: isTokenLoading, token, customerId } = useToken()

  const { logout } = useLogin()

  const customerQuery = useQuery({
    queryKey: ['customer', customerId],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `https://api.commerce7.com/v1/customer/${customerId}`
        )
        const data = response.data

        // Remove uneeded values that change and break the cache
        ;['lastActivityDate', 'updatedAt', 'loginActivity'].forEach(
          (e) => delete data[e]
        )
        return data
      } catch (error) {
        logout()
        throw new Error(
          error.response?.data?.message ||
            'Something went wrong getting the customer',
          { cause: error.response?.data }
        )
      }
    },
    enabled: !!customerId && !!token,
  })

  const allocationQuery = useAllocation()

  const isCustomerLoading =
    isTokenLoading || (customerQuery.isLoading && customerQuery.isFetching)

  if (onNoAccess && !isCustomerLoading && !customerQuery.data) {
    onNoAccess()
  }

  if (onHasAccess && !isCustomerLoading && customerQuery.data) {
    onHasAccess()
  }

  return {
    isCustomerLoading,
    isLoggedIn: !!customerId,
    customer: customerQuery.data,
    customerId,
    ...customerQuery,
    allocation: allocationQuery?.data?.allocation,
    allocationStatus: allocationQuery?.data?.allocationStatus,
    allocationQuery,
  }
}
