import React from 'react'
import Helmet from 'react-helmet'
import { ToastContainer } from 'react-toastify'

import faviconIco from '../images/favicon.ico'
import faviconSvg from '../images/favicon.svg'
import faviconPng from '../images/favicon-96x96.png'
import appleTouchIcon from '../images/apple-touch-icon.png'

import { getPageTitle, getPageSlug } from '@theme/utils/stringManipulations'
import siteConfig from '@theme/utils/siteConfig'

import Header from '../components/Header'
import Footer from '../components/Footer'

const slugToDescriptionMap = {
  home: `BOND: A portfolio of wines that are diverse in their geographic representation and 'Grand Cru' in quality, all under the umbrella of one philosophy, one team, one mark.`,
  'grand-crus': `Sourced from select hillside estates, the Cabernet Sauvignon wines of BOND vividly demonstrate the range of Napa Valley's finest terroirs.`,
  quella:
    'Making its debut with the 2006 vintage, Quella displays an almost ethereal quality of blue fruits, graphite, and a vibrant, subtle finish.',
  melbury:
    'Since its debut with the 1999 vintage, the consistent hallmarks of Melbury have been plush red fruits (currants, bing cherries), redolent with spice and the scent of violets.',
  'st-eden':
    'St. Eden, which appeared first in the 2001 vintage, reliably shows great focus, an opulent "sweet" center and notes of crème de cassis, dark chocolate, and roasted herbs. Mineral-tinged and broad on the palate, the wine consistently displays fine-grained tannins and a lush concentration.',
  vecina: `Vecina, which appeared with the 1999 vintage in the inaugural BOND release, is always powerful, intense and savory. Vecina's other hallmarks are blackberry fruit, with wild forest floor and mineral associated overtones.`,
  pluribus:
    'Pluribus, which debuted in the 2003 vintage, is defined as a bold, rich and concentrated wine; elements of dark plum, roasted coffee, and scents of cedar are inherent throughout the vintages.',
  matriarch:
    'Many components, though singularly attractive and possessing lavish attributes, are not utilized in the final blends for BOND. This provides us the opportunity to create a second wine. This blend, offered under the Matriarch label, will always be evocative of its pedigree and distinct in its hillside character.',
  allocation:
    'To request an allocation of BOND Wine, kindly submit the form here. For merchants, restaurants, and other members of the trade, inquiries should be addressed to...',
  'bottle-verification': 'Please enter your Bottle ID to authenticate.',
  login: 'Access Account | Forgot Password',
}

const Layout = (props) => {
  const { location, children, title } = props
  const pageSlug = getPageSlug(location.pathname)
  const pageTitle = getPageTitle(location.pathname)

  console.log('pageSlug', pageSlug)

  var siteTitle = siteConfig?.metadata?.title + ' Wine'

  var fulltitle = pageTitle + ' | ' + siteTitle

  if (pageSlug == 'home') {
    fulltitle = `${siteConfig?.metadata?.title} | Napa Valley Red Wine | Cabernet Sauvignon`
  } else if (pageSlug == 'allocation') {
    fulltitle = `Join Allocation | ${siteTitle}`
  } else if (pageSlug == 'login') {
    fulltitle = `Member Login | ${siteTitle}`
  } else if (pageSlug == 'matriarch') {
    fulltitle = `Matriarch Napa Valley Red Wine | ${siteTitle}`
  } else if (pageSlug == 'grand-crus') {
    if (pageTitle == 'Grand Crus') {
      fulltitle = `The 5 Grand Crus of BOND | Wine`
    } else {
      fulltitle = `${pageTitle} | Grand Cru Vintage Notes | ${siteTitle}`
    }
  }

  const isHomePage = location.pathname === '/'

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{fulltitle}</title>
        <meta name="description" content={slugToDescriptionMap[pageSlug] || ''} />
        <link rel="shortcut icon" href={faviconIco} />
        <link rel="icon" type="image/png" href={faviconPng} sizes="96x96" />
        <link rel="icon" type="image/svg+xml" href={faviconSvg} />
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <body />
      </Helmet>

      {/* <ErrorBoundary> */}
      {/* <GeneralModalError /> */}

      <div id={`main-page`} className={`${isHomePage ? 'home-page' : ''}`}>
        <ToastContainer
          position="top-left"
          autoClose={3000}
          hideProgressBar={true}
          icon={false}
          style={{ width: '500px', maxWidth: '500px' }}
          theme="light"
        />

        <Header {...props} />

        <main className="content-wrapper mt-4 mx-auto max-w-3xl" id={pageSlug}>
          <div className="content-child mx-auto w-full">
            {/* <PageTitle title={getPageTitle(location.pathname)} /> */}
            {children}
          </div>
        </main>
      </div>

      <Footer {...props} />
      {/* </ErrorBoundary> */}
    </>
  )
}

export default Layout
